
import { Component, Mixins } from 'vue-property-decorator';
import { ListingComponentProps } from '@/types';

import ActivitiesOverviewMixin from '@/mixins/data-operations/collection/activities-overview-mixin';
import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';

import {
	CONFIGURATION_ID,
	CONFIGURATION_TYPE,
	DURATION,
	ENVIRONMENT,
	EXECUTION_DATE_UTC,
	STATUS,
	TRIGGER,
} from '@/constants/data-operations/listing/header-items';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(ActivitiesOverviewMixin, RunCollectionMixin) {
	// TODO: Make common
	getTriggerValue(jobId: string) {
		const splitTrigger = jobId.split('|');
		return splitTrigger[splitTrigger.length - 1];
	}

	get listingComponentProps(): ListingComponentProps {
		return {
			type: this.listingType,
			moduleName: 'tailer-activities-runs',
			headers: [ENVIRONMENT, CONFIGURATION_TYPE, CONFIGURATION_ID, TRIGGER, STATUS, EXECUTION_DATE_UTC, DURATION],
			overriddenColumns: ['configuration_id', 'job_id', 'execution_date_utc', 'duration'],
			isOtherRunDisplay: this.isOtherRunDisplay,
			jobId: this.jobId,
			directExecutionPath: 'configuration_context.configuration.direct_execution',
			customDataFetching: this.getTailerActivitiesRuns,
			customFiltersValues: [this.configurationType],
		};
	}
}


import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ActivitiesOverviewRuns from '@/components/data-operations/activities-overview/runs/CollectionListing.vue';
import CollectionMixin from '@/mixins/data-operations/collection/collection-mixin';

@Component({
	components: { ActivitiesOverviewRuns },
})
export default class ActivitiesOverviewRunsListingView extends Mixins(HeaderInfosMixin, CollectionMixin) {}
